var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "not-found"
  }, [_c('img', {
    attrs: {
      "src": require("../../public/404.webp"),
      "alt": "Not Found"
    }
  }), _c('router-link', {
    staticClass: "not-found__link",
    attrs: {
      "to": "/"
    }
  }, [_vm._v(" Home Page ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }